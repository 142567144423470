import React from "react";
import { Box, CircularProgress } from "@mui/material";
//import logo from "src/assets/images/symbol_green.svg";
import logo from "src/assets/logos/globhe_logo/Globhe_symbol_2022_black.png";
import theme from "../../../theme";
import PropTypes from "prop-types";

const styles = {
  loaderMainContainer: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    margin: "20px",
  },
  main: {
    position: "relative",
    textAlign: "center",
    margin: "auto",
    zIndex: 1000,
    height: "62vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: "50px",
    position: "absolute",
    animation: "rotation 2s infinite linear",
    display: "inline-block",
    transformOrigin: "center center",
    opacity: "30%",
  },
  progressBar: {
    color: theme.palette.black.darkSeaBlack,
    opacity: "5%",
  },
  "@keyframes rotation": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(1deg)",
    },
  },
};
const UniversalLoader = ({ isFromInvite = false, size = 90 }) => {
  const centeredLoaderStyles = isFromInvite
    ? {
        height: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }
    : {};
  return (
    <Box sx={styles.loaderMainContainer}>
      <Box sx={{ ...styles.main, ...centeredLoaderStyles }}>
        <Box
          component="img"
          src={logo}
          sx={{ ...styles.logo, height: `${size}px`, padding: `${size / 6}px` }}
        />
        <CircularProgress size={size} sx={styles.progressBar} />
      </Box>
    </Box>
  );
};
UniversalLoader.propTypes = {
  size: PropTypes.number,
  isFromInvite: PropTypes.bool,
};
// UniversalLoader.defaultProps = {
//   size: 90,
// };

export default UniversalLoader;
